import Editor from './components/editor';
import KetexEditor from './components/ketex';
import MathEditor from './components/mathEditor';
import logo from './logo.svg';

function App() {
  return (
    <div className="">
      {/* <Editor/>
      <KetexEditor/> */}
      <MathEditor/>
    </div>
  );
}

export default App;
