import React, { useEffect, useRef, useState } from "react";
// import "./mathlive.min.js";
import "./mathlive.js";
import "./MathEditor.css";

const MathEditor = () => {
  const [value, setValue] = useState("");
  const mathFieldRef = useRef(null);

  useEffect(() => {
    // Initialize the mathfield element
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const latex = url.searchParams.get("edit")
      ? url.searchParams.get("edit")
      : "";
    const latexValue = decodeURIComponent(latex);
    // console.log(latexValue)

    if (mathFieldRef.current) {
      mathFieldRef.current.setValue(latexValue);
    }
    setValue(latexValue)

  }, []);

  // Update the mathfield when the value changes
  useEffect(() => {
    if (mathFieldRef.current) {
      mathFieldRef.current.setValue(value);
    }
  }, [value]);

  const [spanValue, setSpanValue] = useState("Click to edit me");
  const spanRef = useRef(null);

  const handleInput = () => {
    setSpanValue(spanRef.current.innerText);
  };

  const handleButtonClick = () => {
    const currentUrl = window.location.href;

    // Create a URL object from the current URL
    const url = new URL(currentUrl);

    // Convert the value to UTF-8 and encode it for URL
    const encodedValue = encodeURIComponent(value);

    // Set or modify the query parameters
    url.searchParams.set("code", encodedValue);

    // Navigate to the updated URL
    window.location.href = url.toString();
  };

  return (
    <>
      {/* <h1>MathLive with React</h1> */}
      <math-field
        ref={mathFieldRef}
        onInput={(evt) => setValue(evt.target.value)}
        id="custom-math-field-input"
      >
        {value}
      </math-field>

      <div id="outputSvg"></div>
      {/* <code>Value: {value}</code>

      <div>
        <span
          ref={spanRef}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onInput={handleInput}
          style={{
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          {spanValue}
        </span>
        <p>Current value: {spanValue}</p>
      </div> */}
    </>
  );
};

export default MathEditor;

// Global Error Suppression for ResizeObserver loop limit exceeded
window.addEventListener("error", (e) => {
  if (e.message === "ResizeObserver loop limit exceeded") {
    e.preventDefault();
  }
});
