import { createRef, useState } from "react";
import { addStyles, EditableMathField } from "react-mathquill";
import MathJax from "react-mathjax2";
import "./mathquill.css";
addStyles();
const Editor = () => {
  const [latex, setLatex] = useState(""); // store the LaTeX value
  const [cursorPosition, setCursorPosition] = useState(0); // store the cursor position
  let mathFieldRef = null; // Reference to the MathQuill object

  // Function to handle inserting LaTeX at the cursor position
  const insertLatexAtCursor = (symbol) => {
    if (mathFieldRef) {
      // Get the current latex string before insertion
      const currentLatex = latex;
      // Insert the symbol at the cursor position inside the latex string
      const updatedLatex =
        currentLatex.slice(0, cursorPosition) +
        symbol +
        currentLatex.slice(cursorPosition);

      // Update the latex state
      setLatex(updatedLatex);

      // Optionally set the new cursor position after the inserted symbol
      setCursorPosition(cursorPosition + symbol.length);
    }
  };
  // const [input, setInput] = useState("");
  // const [renderedMath, setRenderedMath] = useState([]);
  // const myRef = createRef();
  // const handleInputChange = (e) => {
  //   const newInput = e.target.value;
  //   setInput(newInput);
  //   setLatex(newInput); // You can process/convert input if needed before rendering
  // };

  // const renderMath = () => {
  //   // Here, you can parse and replace LaTeX-like syntax with your own custom components
  //   const parsedMath = input.split(" ").map((part, index) => {
  //     if (part === "\\frac") {
  //       return (
  //         <div key={index} className="fraction">
  //           <input type="text" className="numerator" placeholder="num" />
  //           <span className="frac-line"></span>
  //           <input type="text" className="denominator" placeholder="den" />
  //         </div>
  //       );
  //     } else if (part === "\\sqrt") {
  //       return (
  //         <div key={index} className="sqrt">
  //           √<input type="text" className="radical" placeholder="radicand" />
  //         </div>
  //       );
  //     } else {
  //       return <span key={index}>{part}</span>;
  //     }
  //   });
  //   setRenderedMath(parsedMath);
  // };
  // const insertSymbol = (symbol) => {
  //   // Insert the symbol into the input at the current cursor position
  //   setInput((prevInput) => prevInput + symbol);
  // };
  return (
    <div className="container py-5">
      <div className="w-full min-h-60 border-2 rounded-md border-neutral-500">
        <div>
          <EditableMathField
            latex={latex}
            onChange={(mathField) => {
              const currentLatex = mathField.latex();
              setLatex(currentLatex); // Update the LaTeX state

              // Track the cursor position inside the LaTeX string
              const cursor = mathField.__controller.cursor;
              console.log(mathField)
              const position =
                cursor?.selection?.endOffset || cursor?.startOffset || 0;
              setCursorPosition(position); // Update cursor position

              mathFieldRef = mathField; // Store reference to the MathQuill field
            }}
            
            className="border p-2 w-full"
          />

          <div className="mt-4 space-x-2">
            {/* Buttons to insert LaTeX symbols */}
            <button
              onClick={() => insertLatexAtCursor("\\sqrt{}")}
              className="btn"
            >
              √
            </button>
            <button
              onClick={() => insertLatexAtCursor("\\frac{}{}")}
              className="btn"
            >
              Fraction
            </button>
            <button
              onClick={() => insertLatexAtCursor("\\sqrt[3]{}")}
              className="btn"
            >
              Cube Root
            </button>
          </div>

          <div className="mt-4">
            <p>LaTeX Output: {latex}</p>
          </div>
        </div>
      </div>
      {/* <div className="math-editor-container">
        <textarea
          className="custom-textarea"
          placeholder="Type LaTeX-like equations here..."
          value={input}
          onChange={handleInputChange}
        />

        <div className="math-icons">
          <button onClick={() => insertSymbol("\\frac")} className="btn">
            Fraction
          </button>
          <button onClick={() => insertSymbol("\\sqrt")} className="btn">
            Square Root
          </button>
        </div>

        <button onClick={renderMath} className="btn render-btn">
          Render Math
        </button>

        <div className="rendered-math">
          {renderedMath.length > 0
            ? renderedMath
            : "Rendered math will appear here..."}
        </div>
      </div> */}
    </div>
  );
};

export default Editor;
